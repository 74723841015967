<template>
  <div class="main-view">
    <div class="tableType">
      <el-radio-group v-model="table.params.status" @change="handleTabs">
        <el-radio-button :label="1">待垫付</el-radio-button>
        <el-radio-button :label="2">待结清</el-radio-button>
        <el-radio-button :label="3">已结清</el-radio-button>
        <el-radio-button :label="4">已取消</el-radio-button>
      </el-radio-group>
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="员工姓名" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="用工单位" prop="company_id">
          <el-select v-model="table.params.company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!--  只有总公司账号才有权限选择开票公司 -->
        <el-form-item v-if="this.table.params.status === 1 && this.icId === 0" label="开票单位" prop="invoice_company_id">
          <el-select v-model="table.params.invoice_company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in invoiceCompanyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="this.table.params.status === 1 || this.table.params.status === 4">
          <el-form-item label="申请垫付日期" prop="applyDate">
            <el-date-picker
              v-model="table.params.applyDate"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </template>
        <template v-if="this.table.params.status === 2 || this.table.params.status === 3">
          <el-form-item label="垫付工资月份" prop="prepay_salary_date">
            <el-date-picker
              v-model="table.params.prepay_salary_date"
              type="month"
              format="yyyy 年 MM 月"
              value-format="yyyy-MM"
              placeholder="选择月">
            </el-date-picker>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <template v-if="this.table.params.status === 1 && this.icId === 0">
          <el-button :size="subUnitSize" type="danger" plain icon="el-icon-finished" @click="handleSendBatch">批量垫付</el-button>
        </template>
        <template v-else-if="this.table.params.status ===1">
          <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
        </template>
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExport">全部导出</el-button>
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExportBatch">批量导出</el-button>
      </div>
    </div>

    <template v-if="this.table.params.status === 1 || this.table.params.status === 4">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:company_id="{row}">
          <span>{{row.company.name}}</span>
        </template>
        <template v-slot:invoice_company_id="{row}">
          <span>{{row.invoice.name}}</span>
        </template>
        <template v-slot:action="{row}">
          
          <template v-if="row.status === 1">
            <el-button type="text" @click='handleReissue(row, icId)'>{{icId == 0 ? '垫付' : '取消'}}</el-button>
          </template>
          <el-button type="text" @click="showDetail(row)">查看</el-button>
          <!-- <el-button type="text" @click='remove(row)'>删除</el-button> -->
        </template>
      </VTable>
    </template>

    <template v-if="this.table.params.status === 2 || this.table.params.status === 3">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field2"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:company_id="{row}">
          <span>{{row.company.name}}</span>
        </template>
        <template v-slot:invoice_company_id="{row}">
          <span>{{row.invoice.name}}</span>
        </template>
        <template v-slot:action="{row}">
          <el-button v-if="icId === 0 && (table.params.status ===1 || table.params.status ===2)" type="text" @click='handleRepayment(row)'>还款</el-button>
          <el-button type="text" @click="showDetail(row)">查看</el-button>
          <!-- <el-button type="text" @click='remove(row)'>删除</el-button> -->
        </template>
      </VTable>
    </template>

    <edit ref="edit" @refresh="getTable" :companyOpt="companyOpt" :curStatus="curStatus"></edit>
    <detail ref="detail"></detail>
    <!-- <dimission ref="dimission" @refresh="getTable"></dimission> -->

    <!-- 垫付 -->
    <el-dialog
      title="垫付"
      :visible.sync="sendInfo.dialogVisible"
      width="30%"
      :before-close="handleClose">
      <p>垫付员工：
        <!-- 批量显示人数，单次显示员工姓名 -->
        <span v-if="this.sendInfo.sendStaff.length >= 1">{{this.sendInfo.sendStaff.length}}人</span>
        <span v-else>{{this.sendInfo.employee_name}}</span>
          <!-- <span v-for="item in this.sendInfo.sendStaff" :key="item">{{item}}&nbsp;&nbsp;</span> -->
      </p>
      <p>垫付金额：￥{{sendInfo.sendSum}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sendInfo.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSend">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="还款"
      :visible.sync="form.dialogVisible"
      width="30%"
      :before-close="handleClose">

      <el-form :model="form.data" ref='elFormDom' :rules='form.rules'>
        <el-form-item label="还款金额" prop="amount">
          <el-input v-model="form.data.amount" autocomplete="off">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="form.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="repayment">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import {exportData} from "@/util/index"
import {getStorage} from '@/storage'
import { mapGetters } from 'vuex';
import { mixinTable } from "@/mixins/table.js";
export default {
  name: 'repaidList',
  mixins: [mixinTable],
  components: {
    Edit,
    Detail,
    // Dimission,
    VTable
  },
  computed: {
    ...mapGetters({
      invoiceCompanyOpt: 'invoiceCompany',  // 获取开票公司
      companyOpt: 'companyList'  // 获取企业
    })
  },
  data() {
    var checkAmount = (rule, value, callback) => {
      console.log(value)
      if (!value) {
        return callback(new Error('还款金额不能为空'));
      }
      setTimeout(() => {
        if (value > this.form.curAmount) {
          callback(new Error(`还款金额必须小于等于${this.form.curAmount}`));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"50"},
        { name: "employee_name", label: "垫付员工", hidden: false },
        { name: "company_id", label: "用工单位", hidden: false},
        { name: "invoice_company_id", label: "所属单位", width: "220", hidden: false},
        { name: "money", label: "垫付金额/元", hidden: false},
        { name: "prepay_salary_date", label: "垫付工资月份", hidden: false},
        { name: "create_time", label: "创建时间", width: "160", hidden: false},
        { name: "apply_date", label: "申请垫付日期", width: "160", hidden: false},
        { name: "action", label: "操作",width: "160", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID", width:"50"},
        { name: "employee_name", label: "垫付员工", hidden: false },
        { name: "company_id", label: "用工单位", hidden: false},
        { name: "invoice_company_id", label: "所属单位", width: "220", hidden: false},
        { name: "money", label: "垫付金额/元", hidden: false},
        { name: "prepay_salary_date", label: "垫付工资月份", hidden: false},
        { name: "create_time", label: "创建时间", width: "160", hidden: false},
        { name: "apply_date", label: "申请垫付日期", width: "160", hidden: false},
        { name: "prepay_time", label: "垫付时间", width: "160", hidden: false},
        { name: "deduct_money", label: "扣款金额/元", hidden: false},
        { name: "manual_deduct_money", label: "还款金额/元", hidden: false},
        { name: "action", label: "操作",width: "160", hidden: false }
      ],
     
      table: {
        loading: false,
        params: {
          ids: [],
          name: '',
          company_id: '',
          invoice_company_id:'',
          status: 1,
          applyDate: [],
          apply_date_min: null,
          apply_date_max: null,
          prepay_salary_date: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      rowId: '',
      isDetail: false,
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
      curStatus: '',

      sendInfo: {
        dialogVisible: false,
        sendStaff: [],
        sendSum: '',
        employee_name: '',
      },
      form: {
        dialogVisible: false,
        curAmount: 0,
        data:{
          id: '',
          amount: '',
        },
        rules:{
          amount: [
            { validator: checkAmount, trigger: 'blur' }
          ]
        },
      }
     
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.curStatus = this.table.params.status;
      this.table.data = [];
      let _applyDate = this.table.params.applyDate
      this.table.params.apply_date_min = _applyDate ? _applyDate[0] : ''
      this.table.params.apply_date_max = _applyDate ? _applyDate[1] : ''
      this.$http.get('/admin/prepayment/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 全部导出
    handleExport() {
      if(this.table.data.length > 0) {
        let _applyDate = this.table.params.applyDate
        let _params = {
          status: this.table.params.status,
          name: this.table.params.name,
          apply_date_min: _applyDate.length > 1 ? _applyDate[0] : '',
          apply_date_max: _applyDate.length > 1 ? _applyDate[1] : ''
        }
        exportData(_params,'/admin/prepayment/export')
      } else {
        this.$message.warning('没有需要导出的数据！')
      }
    },
    // 批量导出
    handleExportBatch() {
      console.log(this.table.params.ids)
      if(this.table.params.ids.length > 0) {
        this.table.params.ids = this.table.params.ids.toString()
        exportData(this.table.params,'/admin/prepayment/export')
      } else  {
         this.$message.warning("请选择要批量导出的数据！");
      }
    },
    // 批量选择的数据
    handleSelectionChange(batchData) {
      this.table.params.ids = [];
      this.sendInfo.sendStaff = [];
      this.sendInfo.sendSum = 0
      if(batchData.length > 0) {
        batchData.forEach(v => {
          this.table.params.ids.push(v.id)
          this.sendInfo.sendStaff.push(v.employee_name)
        })
        this.sendInfo.sendSum = batchData.reduce(this.getSum, 0).toFixed(2);
      }
    },
    getSum(total, num) {
      return total + Number(num.money);
    },
    // 批量垫付
    handleSendBatch(batchData) {
     if(this.table.params.ids.length > 0) {
        this.sendInfo.id = this.table.params.ids.toString()
        this.sendInfo.dialogVisible = true;
      } else  {
         this.$message.warning("请选择要批量垫付的数据！");
      }
    },
    // 垫付/取消
    handleReissue(row, type) {
      // type:0垫付操作，其他是取消操作
      this.sendInfo.sendStaff = [];
      this.sendInfo.sendSum = '';
      if(type === 0) {
        this.sendInfo.dialogVisible = true;
        this.sendInfo.id = row.id
        this.sendInfo.sendSum = row.money
        this.sendInfo.employee_name = row.employee_name
        
      } else {
        this.$confirm('请确认取消吗？','提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post('/admin/prepayment/cancel', {id: row.id}).then(res => {
            if(res.code === 1) {
              this.$message.success('操作成功！')
              this.getTable()
            }
          })
        }).catch(() => {})
      }
    },
    // 垫付
    handleSend() {
      this.$http.post('/admin/prepayment/prepay', { id: this.sendInfo.id }).then(res => {
        if(res.code ===1) {
          this.$message.success('操作成功！')
          this.getTable();
          this.sendInfo.dialogVisible = false;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 关闭弹窗
    handleClose() {
      this.sendInfo.dialogVisible = false;
      this.form.dialogVisible = false;
    },
    // 还款
    handleRepayment(row) {
      this.form.curAmount = Number(row.money) - Number(row.deduct_money) - Number(row.manual_deduct_money)
      this.form.data.amount = this.form.curAmount
      this.form.data.id = row.id,
      console.log(this.form.data.amount);
      this.form.dialogVisible = true;
    },
    repayment() {
      this.$refs.elFormDom.validate(valid => {
        if (valid) {
          let _params = {
            id: this.form.data.id,
            money: this.form.data.amount
          }
          this.$http.post('/admin/prepayment/deduct', _params).then(res => {
            if(res.code === 1) {
              this.$message.success('操作成功！')
              this.table.params.status = 2;
              this.getTable();
              this.form.dialogVisible = false;
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
  }
}
</script>