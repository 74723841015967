<template>
  <el-dialog
    append-to-body
    width="800px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop='employee_id' label='垫付员工：'>
            <el-select v-model="form.data.employee_name" filterable placeholder="请选择" value-key="id" :filter-method="dataFilter" @change="handleEmployeeChange">
              <el-option
                v-for="item in employeeOpt"
                :key="item.id"
                :label="item.show_name"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='company_name' label='所在企业：'>
            <el-input
              v-model='form.data.company_name'
              clearable
              :readonly="true"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='applicant' label='申请人'>
            <el-input
              v-model='form.data.applicant'
              clearable
              placeholder='请输入申请人'
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='money' label='垫付金额'>
            <el-input
              v-model='form.data.money'
              clearable
              placeholder='请输入垫付金额'
              @input="form.data.money=form.data.money.match(/\d+(\.\d{0,2})?/) ? form.data.money.match(/\d+(\.\d{0,2})?/)[0] : ''"
            >
            <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='apply_date' label='申请垫付日期'>
            <el-date-picker
              v-model="form.data.apply_date"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='prepay_salary_date' label='垫付工资月份'>
            <el-date-picker
              v-model="form.data.prepay_salary_date"
              type="month"
              format="yyyy 年 MM 月"
              value-format="yyyy-MM"
              placeholder="选择月">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop='reason' label='垫付理由：'>
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入请输入垫付理由"
              v-model="form.data.reason"
              maxlength="200"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="proof_images" label="同意垫付证明">
            <el-upload
              class="avatar-uploader"
              action=""
              :limit="5"
              list-type="picture-card"
              :headers="{ token: token }"
              :on-preview="handlePictureCardPreview"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleDeleteImg"
              :on-exceed="handleExceed"
              :file-list="imgFileList"
              :http-request="httpRequestBack"
              accept="image/jpeg, image/png, image/gif"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img v-if="dialogVisible" width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-col>
      </el-row> 
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import {getStorage} from '@/storage'
  export default {
    name: 'SystemUnitEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        token: getStorage('token'),
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            employee_id: '',
            employee_name: '',
            company_id: '',
            company_name: '',
            applicant: '',
            money: '',
            apply_date: '',
            prepay_salary_date: '',
            proof_images: '',
            money: '',
          },
          rules: {
            employee_id: [{ required: true, message:'请输垫付员工', trigger: 'change'}],
            // company_name: [{ required: true, message:'请输入', trigger: 'change'}],
            applicant: [{ required: true, message:'请输入申请人', trigger: 'change'}],
            apply_date: [{ required: true, message:'请选择垫付日期', trigger: 'change'}],
            prepay_salary_date: [{ required: true, message:'请选择垫付月份', trigger: 'change'}],
            proof_images: [{ required: true, message:'请选择图片', trigger: 'change'}],
            money: [ {required: true, message:'请输入垫付金额', trigger: 'blur'} ]
          }
        },
        companyOpt: [],
        imgFileList: [],
        dialogVisible: false,
        up_imgurl: '',

        employeeOpt: [],
      }
    },
    mounted() {
      this.up_imgurl = window.localStorage.host + "/common/qiniu/upload";
    },
    methods: {
      // 获取企业详情
      getDetail(row) {
        this.isChange = true
        this.$http.post('/admin/company/detail', { id: row.id }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           this.form.loading = true;
           let imgs = []
            if(this.imgFileList.length > 0) {
              this.imgFileList.forEach(v => {
                imgs.push(v.url)
              })
            }
            this.form.data.proof_images = imgs.toString();
            
            delete this.form.data.employee
            this.$http.post('/admin/prepayment/create', this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
                this.$message.success('操作成功！')
                this.imgFileList = []
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          this.$message.error('上传图片大小不能超过 10MB!');
        }
        return isLt10M;
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制最多只能选择 5 个文件`);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 图片移除
      handleDeleteImg(file, fileList) {
        if(this.imgFileList.length > 0) {
          this.imgFileList.forEach((v, i) => {
            if(file.uid === v.uid) {
              this.imgFileList.splice(i,1)
            }
          })
        }
      },
      httpRequestBack(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.proof_images = res.data.all_path_url
            let strImg = {
              url: res.data.all_path_url,
              name: ''
            }
            this.imgFileList.push(strImg);
            this.$refs.elFormDom.clearValidate();
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      dataFilter(val) {
        this.$http.get('/admin/employee/searchList',{params:{name:val}}).then(res => {
          if(res.code === 1) {
            console.log(res)
            this.employeeOpt = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handleEmployeeChange(value) {
        this.form.data.employee_name = value.name
        this.form.data.employee_id = value.id
        this.form.data.company_id = value.company_id
        this.form.data.company_name = value.company_name
      }
    }
  }
</script>
