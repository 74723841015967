<template>
  <el-dialog
    append-to-body
    width="900px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tableWrap">
      <el-divider content-position="left">垫付信息</el-divider>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">ID</td>
            <td>{{ detailInfo.id }}</td>
            <td colspan="4" class="des" style="width:120px;">垫付员工</td>
            <td>{{ detailInfo.employee_name }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">所在企业</td>
            <td>{{ detailInfo.company_name }}</td>
            <template v-if="this.icId ===0">
              <td colspan="4" class="des" style="width:120px;">开票单位</td>
              <td>{{detailInfo.invoice_company_name}}</td>
            </template>
          </tr>
          <tr>
            <td class="des" style="width:120px;">申请垫付日期</td>
            <td>{{ detailInfo.apply_date }}</td>
            <td colspan="4" class="des" style="width:120px;">垫付工资月份</td>
            <td>{{ detailInfo.prepay_salary_date }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">垫付金额</td>
            <td>{{detailInfo.money}}元</td>
            <td colspan="4" class="des" style="width:120px;">申请人</td>
            <td>{{ detailInfo.applicant }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">垫付理由</td>
            <td>{{ detailInfo.reason }}</td>
            <td colspan="4" class="des" style="width:120px;">状态</td>
            <td>{{ statusObj[detailInfo.status]}}</td>
          </tr>
        </tbody>
      </table>
      <el-divider content-position="left">垫付申请凭证</el-divider>
      <span v-for="img in detailInfo.proof_images" :key="img" style="margin-right:5px;">
        <el-image fil="cover" style="width: 100px; height: 100px" :src="img" :preview-src-list="detailInfo.proof_images"></el-image>
      </span>
      <el-divider content-position="left">基本信息</el-divider>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">员工姓名</td>
            <td>{{ detailInfo.name }}</td>
            <td colspan="4" class="des" style="width:120px;">联系电话</td>
            <td>{{ detailInfo.phone }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">性别</td>
            <td>{{ detailInfo.sex === 1 ? '男' : '女' }}</td>
            <td class="des" colspan="4" style="width:120px;">身份证号</td>
            <td>{{ detailInfo.id_card_no }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">入职时间</td>
            <td>{{ detailInfo.entry_at }}</td>
            <td colspan="4" class="des" style="width:120px;">离职时间</td>
            <td>{{ detailInfo.dimission_at }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">持卡人</td>
            <td>{{ detailInfo.bank_card_name}}</td>
            <td colspan="4" class="des" style="width:120px;">开户行</td>
            <td>{{ detailInfo.bank_name }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">银行卡号</td>
            <td>{{ detailInfo.bank_card_no}}</td>
            <td colspan="4" class="des" style="width:120px;"></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </diV> 
  </el-dialog>
</template>
<script>
  import {getStorage} from '@/storage'
  export default {
    name: 'RepaidDetail',
    data() {
      return {
        icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
        visible: false,
        detailInfo: {},
        statusObj: {
          1: '未垫付',
          2: '未结清',
          3: '已结清',
          4: '已取消'
        },
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      // 获取员工详情
      getDetail(row) {
        if(!!row) {
          this.$http.get('/admin/prepayment/detail', {params:{ id: row.id}}).then(res => {
            if(res.code === 1) {
             this.detailInfo = res.data
             this.detailInfo.proof_images = res.data.proof_images.split(',')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
     
    }
  }
</script>
<style scoped lang="scss">
// 详情自定义table表格样式
.tableWrap .list {
  padding: 0 12px;
  margin-bottom: 30px;
}
 .tableWrap table {
  width: 100%;
  border-collapse: collapse;
}
 .tableWrap table td{
  height: 30px;
  line-height: 22px;
  padding: 5px;
  border: 1px solid #ddd;
}
.tableWrap .des {
  background: #fbfbfb;
   width: 90px;
}
.tableWrap .d-title {
  height: 80px;
  line-height: 80px;
}

</style>
